




















































import { Component, Vue } from 'vue-property-decorator'
import ImageCarousel from '@/components/ImageCarousel.vue'

@Component({
  components: { ImageCarousel },
  metaInfo() {
    return {
      title: `Katie & Erik`,
      meta: [
        {
          name: 'description',
          content:
            "Join us on September 30, 2023, for Katie & Erik's wedding in Rockmart, Georgia.",
        },
        {
          name: 'keywords',
          content:
            'Katie & Erik, Wedding, Katie Reynolds, Erik Maday, The Madays, Rockmart, Georgia, 9.30.23',
        },
        { name: 'robots', content: 'index, follow' },
        // Open Graph / Facebook
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://themadays.com/' },
        { property: 'og:title', content: 'Katie & Erik' },
        {
          property: 'og:description',
          content: 'September 30, 2023 | Rockmart, Georgia',
        },
        // Twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: 'https://themadays.com/' },
        { name: 'twitter:title', content: 'Katie & Erik' },
        {
          name: 'twitter:description',
          content: 'September 30, 2023 | Rockmart, Georgia',
        },
      ],
      link: [{ rel: 'canonical', href: 'https://themadays.com/' }],
    }
  },
})
export default class Landing extends Vue {
  photoIndex = 0
  photos: string[] = []

  // Inside your Landing component
  mounted(): void {
    const path = require.context('@/assets/images/engagement', false, /\.jpg$/)
    this.photos = path.keys().map(path) as string[]
  }
}
