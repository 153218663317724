

































import app from '@/store/modules/app'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  metaInfo() {
    return {
      title: `${app.title}`,
    }
  },
})
export default class ImageCarousel extends Vue {
  @Prop({ type: Number, required: true }) readonly value: number
  @Prop({ type: Array, required: true }) readonly photos: string[]
}
