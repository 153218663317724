var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-full"},[_c('ImageCarousel',{staticClass:"margin-t-n15",style:({ height: '100vh' }),attrs:{"photos":_vm.photos},on:{"change":function($event){_vm.photoIndex = $event}},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"w-full h-full d-flex align-center justify-center flex-column text-center"},[_c('p',{staticClass:"text-white",class:{
            'font-72': _vm.$vuetify.breakpoint.lgAndUp,
            'font-44': _vm.$vuetify.breakpoint.smAndUp,
            'font-36': _vm.$vuetify.breakpoint.xs,
          },style:({ 'line-height': 1 })},[_vm._v(" Katie & Erik ")]),_c('h2',{staticClass:"text-white",class:{
            'font-288': _vm.$vuetify.breakpoint.lgAndUp,
            'font-172': _vm.$vuetify.breakpoint.smAndUp,
            'font-100': _vm.$vuetify.breakpoint.xs,
          },style:({ 'line-height': 1 })},[_vm._v(" 9.30.23 ")]),_c('p',{staticClass:"text-white",class:{
            'font-72 margin-t-5': _vm.$vuetify.breakpoint.lgAndUp,
            'font-44': _vm.$vuetify.breakpoint.smAndUp,
            'font-36 margin-t-2': _vm.$vuetify.breakpoint.xs,
          },style:({ 'line-height': 1 })},[_vm._v(" Rockmart, Georgia ")])])]},proxy:true}]),model:{value:(_vm.photoIndex),callback:function ($$v) {_vm.photoIndex=$$v},expression:"photoIndex"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }